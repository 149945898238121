import { LoadState } from "../../constants/enums";
import { ICountry, ICountryState } from "./country.types";

export const defaultCountry: ICountry = {
  country_uuid: null,
  country_name: "",
  country_currency: "",
  currency_name: "",
  isunique_currencies: "NO",
  status: "ACTIVE",
  created_by_uuid: "",
  modified_by_uuid: "",
};

export const defaultCountryState: ICountryState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  country: {
    data: defaultCountry,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
