import Box from "@mui/material/Box";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logos/1eor_logo.png";

export const LogoIcon: React.FC = () => {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Box display={"flex"} justifyContent="center">
        <img src={Logo} alt="logo not found" style={{ width: "150px" }} />
      </Box>
    </Link>
  );
};
