import { LoadState } from "../../constants/enums";
import { ICountryPedia, ICountryPediaState } from "./countrypedia.types";

export const initialCountryPedia: ICountryPedia = {
  countrypedia_uuid: null,
  country_name: "",
  country_uuid: "",
  flagurl: null,
  imageurl: null,
  slug: null,
  capital: "",
  languages: "",
  currency: "",
  region: "",
  population: "",
  gdp: "",
  gdp_growth: null,
  business: null,
  gdp_share: null,
  meta_title: "",
  meta_description: "",
  content: null,
  comment: null,

  status: "ACTIVE",
};

export const initialCountryPediaState: ICountryPediaState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  countrypedia: {
    data: initialCountryPedia,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
