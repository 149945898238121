import { ActionType } from "typesafe-actions";

import * as BomAction from "./countrykeyActions";

type Actions = typeof BomAction;

export type CountrykeyAction = ActionType<Actions>;

export * from "./countrykeyActions";
export * from "./countrykeyReducer";
export * from "./defaultState";
