// Layout and colors CONSTANTS
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const BLUE_THEME = "BLUE_THEME";
export const LIGHT_BLUE_THEME = "LIGHT_BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";
export const MAROON_THEME = "MAROON_THEME";
export const MAIN_THEME = "MAIN_THEME";
export const USER_LOGOUT = "USER_LOGOUT";

export const DASHBOARD_RESET = "DASHBOARD_RESET";

// INSURER_CODE
export const ADD_INSURER_CODE_PROGESS = "ADD_INSURER_CODE_PROGESS";
export const ADD_INSURER_CODE = "ADD_INSURER_CODE";
export const ADD_INSURER_CODE_ID = "ADD_INSURER_CODE_ID";

export const FETCH_INSURER_CODE_PROGRESS = "FETCH_INSURER_CODE_PROGRESS";
export const FETCH_INSURER_CODE_SUCCESS = "FETCH_INSURER_CODE_SUCCESS";

export const CLEAR_INSURER_STATE = "CLEAR_INSURER_STATE";

// UNDER_WRITER
export const ADD_UNDER_WRITER_PROGESS = "ADD_UNDER_WRITER_PROGESS";
export const ADD_UNDER_WRITER = "ADD_UNDER_WRITER";
export const ADD_UNDER_WRITER_ID = "ADD_UNDER_WRITER_ID";

export const FETCH_UNDER_WRITER_PROGRESS = "FETCH_UNDER_WRITER_PROGRESS";
export const FETCH_UNDER_WRITER_SUCCESS = "FETCH_UNDER_WRITER_SUCCESS";

export const CLEAR_UNDER_WRITER_STATE = "CLEAR_INSURER_STATE";

// CSIO Company
export const ADD_CSIO_COMPANY_PROGESS = "ADD_CSIO_COMPANY_PROGESS";
export const ADD_CSIO_COMPANY = "ADD_CSIO_COMPANY";
export const ADD_CSIO_COMPANY_ID = "ADD_CSIO_COMPANY_ID";

export const FETCH_CSIO_COMPANY_PROGRESS = "FETCH_CSIO_COMPANY_PROGRESS";
export const FETCH_CSIO_COMPANY_SUCCESS = "FETCH_CSIO_COMPANY_SUCCESS";

export const CLEAR_CSIO_COMPANY_STATE = "CLEAR_INSURER_STATE";

export const CSIO_COMPANY_ERROR = "CSIO_COMPANY_ERROR";
export const ADD_CSIO_COMPANY_ERROR = "ADD_CSIO_COMPANY_ERROR";

// BROKER_CODE
export const ADD_BROKER_CODE_PROGRESS = "ADD_BROKER_CODE_PROGRESS";
export const ADD_BROKER_CODE = "ADD_BROKER_CODE";
export const ADD_BROKER_CODE_ID = "ADD_BROKER_CODE_ID";

export const FETCH_BROKER_CODE_PROGRESS = "FETCH_BROKER_CODE_PROGRESS";
export const FETCH_BROKER_CODE_SUCCESS = "FETCH_BROKER_CODE_SUCCESS";

export const CLEAR_BROKER_CODE_STATE = "CLEAR_INSURER_STATE";

export const BROKER_CODE_ERROR = "BROKER_CODE_ERROR";
export const ADD_BROKER_CODE_ERROR = "ADD_BROKER_CODE_ERROR";

// BRANCH_OFFICE
export const ADD_BRANCH_OFFICE_PROGRESS = "ADD_BRANCH_OFFICE_PROGRESS";
export const ADD_BRANCH_OFFICE = "ADD_BRANCH_OFFICE";
export const ADD_BRANCH_OFFICE_ID = "ADD_BRANCH_OFFICE_ID";

export const FETCH_BRANCH_OFFICE_PROGRESS = "FETCH_BRANCH_OFFICE_PROGRESS";
export const FETCH_BRANCH_OFFICE_SUCCESS = "FETCH_BRANCH_OFFICE_SUCCESS";

export const CLEAR_BRANCH_OFFICE_STATE = "CLEAR_INSURER_STATE";

export const BRANCH_OFFICE_ERROR = "BRANCH_OFFICE_ERROR";
export const ADD_BRANCH_OFFICE_ERROR = "ADD_BRANCH_OFFICE_ERROR";

// SUB_INSURER
export const ADD_SUB_INSURER_PROGRESS = "ADD_SUB_INSURER_PROGRESS";
export const ADD_SUB_INSURER = "ADD_SUB_INSURER";
export const ADD_SUB_INSURER_ID = "ADD_SUB_INSURER_ID";

export const FETCH_SUB_INSURER_PROGRESS = "FETCH_SUB_INSURER_PROGRESS";
export const FETCH_SUB_INSURER_SUCCESS = "FETCH_SUB_INSURER_SUCCESS";

export const CLEAR_SUB_INSURER_STATE = "CLEAR_INSURER_STATE";

export const SUB_INSURER_ERROR = "SUB_INSURER_ERROR";
export const ADD_SUB_INSURER_ERROR = "ADD_SUB_INSURER_ERROR";

// POLICY_COMMISSION
export const ADD_POLICY_COMMISSION_PROGRESS = "ADD_POLICY_COMMISSION_PROGRESS";
export const ADD_POLICY_COMMISSION = "ADD_POLICY_COMMISSION";
export const ADD_POLICY_COMMISSION_ID = "ADD_POLICY_COMMISSION_ID";

export const FETCH_POLICY_COMMISSION_PROGRESS =
  "FETCH_POLICY_COMMISSION_PROGRESS";
export const FETCH_POLICY_COMMISSION_SUCCESS =
  "FETCH_POLICY_COMMISSION_SUCCESS";

export const CLEAR_POLICY_COMMISSION_STATE = "CLEAR_INSURER_STATE";

export const POLICY_COMMISSION_ERROR = "POLICY_COMMISSION_ERROR";
export const ADD_POLICY_COMMISSION_ERROR = "ADD_POLICY_COMMISSION_ERROR";

// INSURER_CONTACTS
export const ADD_INSURER_CONTACTS_PROGRESS = "ADD_INSURER_CONTACTS_PROGRESS";
export const ADD_INSURER_CONTACTS = "ADD_INSURER_CONTACTS";
export const ADD_INSURER_CONTACTS_ID = "ADD_INSURER_CONTACTS_ID";

export const FETCH_INSURER_CONTACTS_PROGRESS =
  "FETCH_INSURER_CONTACTS_PROGRESS";
export const FETCH_INSURER_CONTACTS_SUCCESS = "FETCH_INSURER_CONTACTS_SUCCESS";

export const CLEAR_INSURER_CONTACTS_STATE = "CLEAR_INSURER_STATE";

export const INSURER_CONTACTS_ERROR = "INSURER_CONTACTS_ERROR";
export const ADD_INSURER_CONTACTS_ERROR = "ADD_INSURER_CONTACTS_ERROR";

// INTERESTED PARTY
export const ADD_INTERESTED_PARTY_PROGRESS = "ADD_INTERESTED_PARTY_PROGRESS";
export const ADD_INTERESTED_PARTY = "ADD_INTERESTED_PARTY";
export const ADD_INTERESTED_PARTY_ID = "ADD_INTERESTED_PARTY_ID";

export const FETCH_INTERESTED_PARTY_PROGRESS =
  "FETCH_INTERESTED_PARTY_PROGRESS";
export const FETCH_INTERESTED_PARTY_SUCCESS = "FETCH_INTERESTED_PARTY_SUCCESS";

export const CLEAR_INTERESTED_PARTY_STATE = "CLEAR_INTERESTED_PARTY";

export const INTERESTED_PARTY_ERROR = "INTERESTED_PARTY_ERROR";
export const ADD_INTERESTED_PARTY_ERROR = "ADD_INTERESTED_PARTY_ERROR";

// ADDITIONAL INTEREST
export const ADD_ADDITIONAL_INTEREST_PROGRESS =
  "ADD_ADDITIONAL_INTEREST_PROGRESS";
export const ADD_ADDITIONAL_INTEREST = "ADD_ADDITIONAL_INTEREST";
export const ADD_ADDITIONAL_INTEREST_ID = "ADD_ADDITIONAL_INTEREST_ID";

export const FETCH_ADDITIONAL_INTEREST_PROGRESS =
  "FETCH_ADDITIONAL_INTEREST_PROGRESS";
export const FETCH_ADDITIONAL_INTEREST_SUCCESS =
  "FETCH_ADDITIONAL_INTEREST_SUCCESS";

export const CLEAR_ADDITIONAL_INTEREST_STATE = "CLEAR_ADDITIONAL_INTEREST";

export const ADDITIONAL_INTEREST_ERROR = "ADDITIONAL_INTEREST_ERROR";
export const ADD_ADDITIONAL_INTEREST_ERROR = "ADD_ADDITIONAL_INTEREST_ERROR";

// USER PROFILE
export const FETCH_USER_PROFILE_PROGRESS = "FETCH_USER_PROFILE_PROGRESS";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
