import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { CountryActions } from ".";
import {
  CLEAR_COUNTRY,
  CLEAR_COUNTRY_STATE,
  FETCH_COUNTRY_LIST_FAILED,
  FETCH_COUNTRY_LIST_PROGRESS,
  FETCH_COUNTRY_LIST_SUCCESS,
} from "./countryActions";
import { defaultCountry, defaultCountryState } from "./defaultState";

export const countryReducer = (
  state: IStoreState["country"] = defaultCountryState,
  action: CountryActions,
) => {
  switch (action.type) {
    case FETCH_COUNTRY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COUNTRY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COUNTRY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_COUNTRY: {
      const newState = produce(state, (draftState) => {
        draftState.country.loading = LoadState.NotLoaded;
        draftState.country.data = defaultCountry;
      });
      return newState;
    }
    case CLEAR_COUNTRY_STATE: {
      return defaultCountryState;
    }
    default: {
      return state;
    }
  }
};
