import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import {
  CLEAR_COUNTRYPEDIA,
  CLEAR_COUNTRYPEDIA_STATE,
  CountrypediaAction,
  FETCH_COUNTRYPEDIA_FAILED,
  FETCH_COUNTRYPEDIA_LIST_FAILED,
  FETCH_COUNTRYPEDIA_LIST_PROGRESS,
  FETCH_COUNTRYPEDIA_LIST_SUCCESS,
  FETCH_COUNTRYPEDIA_PROGRESS,
  FETCH_COUNTRYPEDIA_SUCCESS,
} from ".";
import { initialCountryPedia, initialCountryPediaState } from "./defaultState";

export const countryPediaReducer = (
  state: IStoreState["countrypedia"] = initialCountryPediaState,
  action: CountrypediaAction,
) => {
  switch (action.type) {
    case FETCH_COUNTRYPEDIA_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_COUNTRYPEDIA_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COUNTRYPEDIA_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_COUNTRYPEDIA_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.countrypedia.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COUNTRYPEDIA_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.countrypedia.loading = LoadState.Loaded;
        draftState.countrypedia.data = data;
      });
      return newState;
    }
    case FETCH_COUNTRYPEDIA_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.countrypedia.loading = LoadState.Failed;
        draftState.countrypedia.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_COUNTRYPEDIA: {
      const newState = produce(state, (draftState) => {
        draftState.countrypedia.loading = LoadState.NotLoaded;
        draftState.countrypedia.data = initialCountryPedia;
        draftState.countrypedia.error = null;
      });
      return newState;
    }

    case CLEAR_COUNTRYPEDIA_STATE: {
      return initialCountryPediaState;
    }

    default: {
      return state;
    }
  }
};
