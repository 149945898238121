import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MODULE_IDS } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { OOPSError } from "../views/authentication/OopsError";

import { ForgetPassword } from "../views/authentication/ForgetPassword/ForgetPassword";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    })),
  ),
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout")),
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    })),
  ),
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    })),
  ),
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    })),
  ),
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    })),
  ),
);

const CountryList = Loadable(
  lazy(() =>
    import("../views/country/CountryList").then(({ CountryList }) => ({
      default: CountryList,
    })),
  ),
);
const AssumptionList = Loadable(
  lazy(() =>
    import("../views/assumption/AassumptionList").then(
      ({ AssumptionList }) => ({
        default: AssumptionList,
      }),
    ),
  ),
);

// const ManageAssumption = Loadable(
//   lazy(() =>
//     import("../views/assumption/ManageAssumption").then(
//       ({ ManageAssumption }) => ({
//         default: ManageAssumption,
//       }),
//     ),
//   ),
// );

const SalaryList = Loadable(
  lazy(() =>
    import("../views/salary/SalaryList").then(({ SalaryList }) => ({
      default: SalaryList,
    })),
  ),
);

const ManageSalary = Loadable(
  lazy(() =>
    import("../views/salary/ManageSalary").then(({ ManageSalary }) => ({
      default: ManageSalary,
    })),
  ),
);

const CostCalculator = Loadable(
  lazy(() =>
    import("../views/costcalculator/costCalculator").then(
      ({ CostCalculator }) => ({
        default: CostCalculator,
      }),
    ),
  ),
);
const PublicCostCalculator = Loadable(
  lazy(() =>
    import("../views/publicCostCalculator/publicCostCalculator").then(
      ({ PublicCostCalculator }) => ({
        default: PublicCostCalculator,
      }),
    ),
  ),
);

const CountryPediaList = Loadable(
  lazy(() =>
    import("../views/countrypedia/CountryPediaList").then(
      ({ CountryPediaList }) => ({
        default: CountryPediaList,
      }),
    ),
  ),
);

const ManageCountryPedia = Loadable(
  lazy(() =>
    import("../views/countrypedia/ManageCountryPedia").then(
      ({ ManageCountryPedia }) => ({
        default: ManageCountryPedia,
      }),
    ),
  ),
);

const CountryKeyList = Loadable(
  lazy(() =>
    import("../views/CountryKey/CountryKeyList").then(({ CountryKeyList }) => ({
      default: CountryKeyList,
    })),
  ),
);

const ManageCountryKey = Loadable(
  lazy(() =>
    import("../views/CountryKey/ManageCountryKey").then(
      ({ ManageCountryKey }) => ({
        default: ManageCountryKey,
      }),
    ),
  ),
);

// hr applications
const AllApplicationHr = Loadable(
  lazy(() =>
    import("../views/OnBoarding/AllApplication/AllApplication").then(
      ({ AllApplicationHR }) => ({
        default: AllApplicationHR,
      }),
    ),
  ),
);
const OnBoardingList = Loadable(
  lazy(() =>
    import("../views/OnBoarding/OnBoardingStepOne").then(({ OnBoarding }) => ({
      default: OnBoarding,
    })),
  ),
);

const OffBoarding = Loadable(
  lazy(() =>
    import("../views/OnBoarding/Offboarding/Offboarding").then(
      ({ OffBoarding }) => ({
        default: OffBoarding,
      }),
    ),
  ),
);

const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    })),
  ),
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      }),
    ),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup }),
    ),
  ),
);

const SecurityApprovalsList = Loadable(
  lazy(() =>
    import("../views/Security/approval/ApprovalList").then(
      ({ ApprovalList }) => ({ default: ApprovalList }),
    ),
  ),
);

const ManageSecuirtyApproval = Loadable(
  lazy(() =>
    import("../views/Security/approval/ManageApproval").then(
      ({ ManageApproval }) => ({ default: ManageApproval }),
    ),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);

export const Router = [
  {
    path: "/",

    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/country",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <CountryList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/assumption",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <AssumptionList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/assumption/:countryName/:countryUuid",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <AssumptionList />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/assumption/manage",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute allowFullAccess moduleIds={[]}>
      //       <ManageAssumption />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/assumption/manage/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute allowFullAccess moduleIds={[]}>
      //       <ManageAssumption />
      //     </AuthorizedRoute>
      //   ),
      // },
      {
        path: "/salarycomponent",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <SalaryList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/salarycomponent/:countryName/:countryUuid",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageSalary />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/costcalculator",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <CostCalculator />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/countrypedia",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <CountryPediaList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/countrypedia/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageCountryPedia />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/countrypedia/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageCountryPedia />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/country-key",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <CountryKeyList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/country-key/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageCountryKey />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/country-key/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageCountryKey />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/publiccostcalculator",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute allowFullAccess moduleIds={[]}>
      //       <PublicCostCalculator />
      //     </AuthorizedRoute>
      //   ),
      // },
      {
        path: "/salarycomponent/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageSalary />
          </AuthorizedRoute>
        ),
      },

      // hr applicatins

      {
        path: "/all-applications",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <AllApplicationHr />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/on-boarding",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/on-boarding/:applicationNumber",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/on-boardings",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/view-profile",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ViewProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/approvals-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityApprovalsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval/:approvalId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ],
  },

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "forget-password", element: <ForgetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  {
    path: "/public/costcalculator",
    element: <PublicCostCalculator />,
  },
];
