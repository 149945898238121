import { Autocomplete, Box, CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";

import { CustomFormLabel, CustomTextField } from "../formsComponents";

import { useDispatchWrapper } from "../../hooks";
import { initialSalary } from "../../redux/salary";
import { ISalary } from "../../redux/salary/salary.types";
import { IUser } from "../../redux/UserProfileList/userProfile.types";
import { initialUser } from "../../redux/UserProfileList/userprofileState";

const INITIAL_STATE: IUser = initialUser;
interface IBranchAutoSearch {
  label: string;
  value: {
    branch_name: string;
    branch_uuid: string;
  } | null;
  onSelect: (value: IUser) => void;
  disabled?: boolean;
  error?: string;
}

export const BranchAutoSearch: React.FC<IBranchAutoSearch> = (props) => {
  const { label, value, onSelect, disabled, error } = props;

  // states
  const [options, setOptions] = React.useState<readonly IUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [search, setSearchText] = React.useState<any>("");
  const dispatch = useDispatchWrapper();

  // fetch options from api
  const fetchSuggestion = async (searchValue: string) => {
    setLoading(true);
    try {
      let apiEndPoint = "";
      if (searchValue) {
        apiEndPoint = `?value=${searchValue}&columns=branch_name`;
      }
      const res = await api.get(`/user/get-branch${apiEndPoint}`);
      const data: IUser[] = res.data.data;

      //remove duplicate options

      const uniqueOptions = data.reduce((unique: IUser[], current) => {
        if (!unique.some((item) => item.branch_name === current.branch_name)) {
          unique.push(current);
        }
        return unique;
      }, []);

      setOptions(uniqueOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  //get option label
  const getOptionLabel = (option: string | IUser) => {
    if (typeof option === "string") return option;
    return `${option.branch_name || ""}`;
  };

  // get the saved value
  const getValue = () => {
    if (value && typeof value === "object") {
      return (
        options.find((option) => option.branch_name === value?.branch_uuid) ||
        null
      );
    }
    return options.find((option) => option.branch_uuid === value) || null;
  };

  const getValueLabel = (value: IUser | null) => {
    if (value && typeof value === "object") {
      return value.branch_name;
    }
    return "";
  };

  React.useEffect(() => {
    if (value && value.branch_uuid?.length > 0) {
      const option: IUser = {
        ...initialUser,
        branch_uuid: value.branch_uuid,
        branch_name: value.branch_name,
      };
      setOptions([option]);
    }
  }, [value]);

  // ***********Right panel sart************
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogData, setDialogData] = React.useState<ISalary>(initialSalary);
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      ...initialSalary,
      country_name: search,
    });
  };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   dispatch(clearCountry());
  // };
  // const handleComplete = (data: ISalary) => {
  //   setOpenDialog(false);
  //   dispatch(clearCountry());
  //   onSelect(data);
  // };
  // ************Right panel ends**********

  React.useEffect(() => {
    if (openData) {
      debounceFn(search);
    }
  }, [openData, search]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {search.length > 0 && options.length === 0 && !loading && (
            <Box
              sx={{
                paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenDialog()}
            ></Box>
          )}
        </Box>
      )}
      <Autocomplete
        id="country-auto-search"
        freeSolo
        options={options}
        value={getValueLabel(getValue())}
        getOptionLabel={getOptionLabel}
        noOptionsText="No Country"
        filterOptions={(x) => x}
        onFocus={() => setOpenData(true)}
        loading={loading}
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: IUser | null,
        ) => {
          if (newValue) {
            const isOptionExists = options.some(
              (option) => option.branch_uuid === newValue.branch_uuid,
            );
            setOptions(isOptionExists ? options : [newValue, ...options]);
            onSelect(newValue);
          } else {
            onSelect(INITIAL_STATE);
          }
        }}
        autoComplete
        includeInputInList
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disabled={disabled}
            error={error ? true : false}
            helperText={error}
            placeholder={value?.branch_name as string}
          />
        )}
        isOptionEqualToValue={(option, value) =>
          typeof option === "string"
            ? option === value
            : //@ts-ignore
              option.country_name === value.country_name
        }
      />
    </>
  );
};
