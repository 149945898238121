import { api } from "../api/api";

export const uploadFile = async (
  image: File | null,
  // module_name: string,
  defaultReturn: string,
  // asPayload: {
  //   [key: string]: string | number | null;
  // }
) => {
  if (image) {
    const formdata = new FormData();
    formdata.append("image", image);

    console.log("step 1");

    // formdata.append("module_name", module_name);

    // formdata.append("as_payload", JSON.stringify(asPayload));
    const res = await api.post("/website/upload-image", formdata);
    const data = res.data.data;

    console.log(formdata);

    console.log(data, "data");

    const path = data[0].imagePath;

    return path;
    // if (data.length > 0) {

    //   const imageUrl = `https://api.1eor.com/${data[0].imagePath}`;
    //   return imageUrl;
    // }
  }
  return defaultReturn;
};
