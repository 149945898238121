import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import { initialSalary, initialSalaryState } from "./defaultState";
import {
  CLEAR_SALARY,
  CLEAR_SALARY_STATE,
  FETCH_SALARY_FAILED,
  FETCH_SALARY_LIST_FAILED,
  FETCH_SALARY_LIST_PROGRESS,
  FETCH_SALARY_LIST_SUCCESS,
  FETCH_SALARY_PROGRESS,
  FETCH_SALARY_SUCCESS,
  SalaryActions,
} from ".";

export const salaryReducer = (
  state: IStoreState["salary"] = initialSalaryState,
  action: SalaryActions,
) => {
  switch (action.type) {
    case FETCH_SALARY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_SALARY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SALARY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_SALARY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.salary.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SALARY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.salary.loading = LoadState.Loaded;
        draftState.salary.data = data;
      });
      return newState;
    }
    case FETCH_SALARY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.salary.loading = LoadState.Failed;
        draftState.salary.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SALARY: {
      const newState = produce(state, (draftState) => {
        draftState.salary.loading = LoadState.NotLoaded;
        draftState.salary.data = initialSalary;
        draftState.salary.error = null;
      });
      return newState;
    }

    case CLEAR_SALARY_STATE: {
      return initialSalaryState;
    }

    default: {
      return state;
    }
  }
};
