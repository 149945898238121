
export const STANDARD_APP_DATE_FORMAT = "DD/MM/YYYY";
export const STANDARD_APP_TIME_FORMAT = "hh:mm A";
export const STANDARD_APP_DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm A";
export const error_message = {
  required: "This field is required",
  email: "Email is required",
  invalid_email: "Invalid email address",
  phone: "Phone is required",
  date: "Date is required",
};

