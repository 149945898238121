import { ActionType } from "typesafe-actions";

import * as BomAction from "./countryPediaActions";

type Actions = typeof BomAction;

export type CountrypediaAction = ActionType<Actions>;

export * from "./countryPediaActions";
export * from "./countryPediaReducer";
export * from "./defaultState";
