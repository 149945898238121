import { LoadState } from "../../constants/enums";
import { IAssumption, IAssumptionState } from "./assumption.types";

export const initialAssumption: IAssumption = {
  assumption_uuid: null,
  assumption_name: null,
  assumption_variable: null,
  value: null,
  comment: null,
  country_name: null,
  country_uuid: null,
  modified_by_uuid: null,
  created_by_uuid: null,
  status: "ACTIVE",
};

export const initialAssumptionState: IAssumptionState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  assumption: {
    data: initialAssumption,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
