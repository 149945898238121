import { LoadState } from "../../constants/enums";
import { ISalary, ISalaryState } from "./salary.types";

export const initialSalary: ISalary = {
  country_salary_component_uuid: null,
  component_name: "",
  component_variable: "",
  formula: "",
  country_uuid: "",
  country_name: "",
  comment: "",
  status: "ACTIVE",
};

export const initialSalaryState: ISalaryState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  salary: {
    data: initialSalary,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
