export const numberToWords = (number: any) => {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
  ];

  if (number === 0) return "zero";

  let words = "";
  let i = 0;

  while (number > 0) {
    if (number % 1000 !== 0) {
      words = `${convertHundred(number % 1000)} ${scales[i]} ${words}`;
    }
    number = Math.floor(number / 1000);
    i++;
  }

  return words.trim();
};

const convertHundred = (number: any) => {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  let word = "";

  const hundreds = Math.floor(number / 100);
  const remainder = number % 100;

  if (hundreds > 0) {
    word = `${ones[hundreds]} hundred`;
  }

  if (remainder > 0) {
    if (remainder < 20) {
      word += ` ${ones[remainder]}`;
    } else {
      const tensDigit = Math.floor(remainder / 10);
      const onesDigit = remainder % 10;
      word += ` ${tens[tensDigit]} ${ones[onesDigit]}`;
    }
  }

  return word.trim();
};
