import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_COUNTRYKEY,
  CLEAR_COUNTRYKEY_STATE,
  FETCH_COUNTRYKEY_FAILED,
  FETCH_COUNTRYKEY_LIST_FAILED,
  FETCH_COUNTRYKEY_LIST_PROGRESS,
  FETCH_COUNTRYKEY_LIST_SUCCESS,
  FETCH_COUNTRYKEY_PROGRESS,
  FETCH_COUNTRYKEY_SUCCESS,
} from "./countrykeyActions";
import { initialCountryKey, initialCountryKeyState } from "./defaultState";
import { CountrykeyAction } from ".";

export const countryKeyReducer = (
  state: IStoreState["countrykey"] = initialCountryKeyState,
  action: CountrykeyAction,
) => {
  switch (action.type) {
    case FETCH_COUNTRYKEY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_COUNTRYKEY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COUNTRYKEY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_COUNTRYKEY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.countrykey.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COUNTRYKEY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.countrykey.loading = LoadState.Loaded;
        draftState.countrykey.data = data;
      });
      return newState;
    }
    case FETCH_COUNTRYKEY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.countrykey.loading = LoadState.Failed;
        draftState.countrykey.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_COUNTRYKEY: {
      const newState = produce(state, (draftState) => {
        draftState.countrykey.loading = LoadState.NotLoaded;
        draftState.countrykey.data = initialCountryKey;
        draftState.countrykey.error = null;
      });
      return newState;
    }

    case CLEAR_COUNTRYKEY_STATE: {
      return initialCountryKeyState;
    }

    default: {
      return state;
    }
  }
};
