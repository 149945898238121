import { LoadState } from "../../constants/enums";
import { ICountryKey, ICountryKeyState } from "./countrykey.types";

export const initialCountryKey: ICountryKey = {
  country_key_uuid: null,
  country_name: "",
  country_uuid: "",
  country_unique_key: "",
  comment: null,
  status: "ACTIVE",
};

export const initialCountryKeyState: ICountryKeyState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  countrykey: {
    data: initialCountryKey,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
