import React from "react";

import { CustomTextField } from "./CustomTextField";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";

export const CustomDatePicker: React.FC<{
  fieldName?: string;
  value: string;
  sx?: any;
  disabled?: boolean;
  views?: ("day" | "month" | "year")[];
  inputFormat?: string;
  fullWidth?: boolean;
  onChange: (value: any, formattedDate: string) => void;
  error?: boolean;
  helperText?: string;
}> = (props) => {
  const {
    fieldName,
    value,
    sx,
    views = ["year", "month", "day"],
    inputFormat = "MM-DD-YYYY",
    fullWidth,
    disabled,
    error,
    helperText,
  } = props;
  return (
    <>
      <DatePicker
        name={fieldName}
        views={views}
        key={value}
        disabled={disabled}
        format={inputFormat}
        sx={{
          width: fullWidth ? "100%" : "unset",
          "& .MuiOutlinedInput-input": {
            padding: "9px 13px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={moment(value) ? moment(value) : null}
        onChange={(newValue) => {
          const formattedDate = moment(newValue).format("YYYY-MM-DD");
          props.onChange(newValue, formattedDate);
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.75rem",
          color: "#e46a76",
          marginLeft: 2,
          marginTop: 0.75,
        }}
      >
        {error && helperText}
      </Typography>
    </>
  );
};

export const CustomTimePicker: React.FC<{
  value: Moment;
  sx?: any;
  fullWidth?: boolean;
  onChange: (value: Moment) => void;
}> = (props) => {
  const { value, sx, fullWidth } = props;
  return (
    <TimePicker
      value={moment(value)}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      format="h:mm A"
      onChange={(newValue) => {
        if (newValue) {
          props.onChange(newValue);
        }
      }}
      sx={{
        width: fullWidth ? "100%" : "unset",
        "& .MuiOutlinedInput-input": {
          padding: "9px 13px",
          fontSize: "0.8rem",
          color: "rgb(38, 38, 38)",
        },
      }}
    />
  );
};
