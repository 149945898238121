import { ActionType } from "typesafe-actions";

import * as BomAction from "./salaryActions";

type Actions = typeof BomAction;

export type SalaryActions = ActionType<Actions>;

export * from "./salaryActions";
export * from "./salaryReducer";
export * from "./defaultState";
