import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { ISelectOption } from "../../constants/types";
import { ClearIcon } from "@mui/x-date-pickers";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { CustomFormLabel } from "./CustomFormLabel";

export const CustomSelect = styled((props: SelectProps) => (
  <Select {...props} MenuProps={MenuProps} />
))(({ theme }) => ({
  "& .MuiSelect-select": {
    color: "#767e89",
    padding: "9px 13px",
    fontSize: "0.87rem",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
  },
  "& .MuiSelect-select::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));

interface ICustomSelectProps extends SelectProps {
  options: { label: number | string | null; value: string | number }[];
  helperText?: string;
  readOnlyMode?: boolean;
  optionalData?: ISelectOption[];
  clearable?: boolean;
  onClear?: () => void;
}

const CustomClearIcon = styled(ClearIcon)(({ theme }) => ({
  fontSize: "1.2rem",
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const ControlledCustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const renderPlaceholder = () => props.placeholder;
  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    }
  };

  const getOptionValue = () => {
    if (props.multiple && Array.isArray(props.value)) {
      return props.value;
    } else if (props.multiple || props.value === "[]") {
      return [];
    } else if (props.multiple) {
      return [props.value];
    }
    return props.value || "";
  };

  return (
    <>
      <CustomSelect
        {...props}
        multiple={props.multiple}
        value={getOptionValue()}
        renderValue={(selected) => {
          if (selected) {
            return getLabelFromValue(selected, props.options);
          }
          return renderPlaceholder();
        }}
        endAdornment={
          props.clearable && props.value ? (
            <InputAdornment position="end" sx={{ marginRight: "7px" }}>
              <ClearButton onClick={handleClear}>
                <CustomClearIcon />
              </ClearButton>
            </InputAdornment>
          ) : null
        }
      >
        {props.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.value.indexOf(option.value) > -1} />
              )}
              {option.label}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {props.helperText && <ErrorMessage value={props.helperText} />}
    </>
  );
};

const getLabelFromValue = (
  value: any,
  options: ICustomSelectProps["options"],
) => {
  if (Array.isArray(value)) {
    const data = options.filter((option) => {
      if (value.includes(option.value)) {
        return true;
      }
      return false;
    });
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {data
          .map((x) => x.label)
          .map((value) => (
            <Chip key={value} label={value} size="small" />
          ))}
      </Box>
    );
    // return value;
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : "";
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICustomSelectorProps extends Omit<SelectProps, "error"> {
  label?: string;
  options: string[] | number[];
  error?: string;
  readOnlyMode?: boolean;
}

export const CustomSelectorWithLabel: React.FC<ICustomSelectorProps> = (
  props,
) => {
  const { error, label, readOnlyMode, ...restProps } = props;
  return (
    <>
      {label && <CustomFormLabel>{label}</CustomFormLabel>}
      {props.readOnlyMode ? (
        <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
          <>{props.value || "--"}</>
        </Typography>
      ) : (
        <CustomSelect
          {...restProps}
          className={props.name}
          value={props.value}
          placeholder={props.placeholder}
        >
          {props.options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {/* {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.options.indexOf(option) > -1} />
              )} */}
                {option}
              </MenuItem>
            );
          })}
        </CustomSelect>
      )}
      {error && (
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.75rem",
            color: "#e46a76",
            marginLeft: 2,
            marginTop: 0.75,
          }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};
