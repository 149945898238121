import { createStore, applyMiddleware, Middleware, AnyAction } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./RootReducer";
import { initialStoreState, IStoreState } from "./initialStoreState";
import { thunk, ThunkMiddleware } from "redux-thunk";

const store = createStore(
  rootReducer,
  initialStoreState,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<IStoreState, AnyAction>),
  ),
);

export default store;
