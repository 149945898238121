// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-disabled {
  background-color: gray;
  opacity: 0.8;
}

.tableScroll ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
.tableScroll ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.tableScroll ::-webkit-scrollbar-thumb {
  background: #7f7f7f;
  border-radius: 10px;
}

/* Handle on hover */
.tableScroll ::-webkit-scrollbar-thumb:hover {
  background: #7f7f7f;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/DataTable.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB","sourcesContent":[".row-disabled {\n  background-color: gray;\n  opacity: 0.8;\n}\n\n.tableScroll ::-webkit-scrollbar {\n  height: 8px;\n  width: 8px;\n}\n\n/* Track */\n.tableScroll ::-webkit-scrollbar-track {\n  border-radius: 10px;\n}\n\n/* Handle */\n.tableScroll ::-webkit-scrollbar-thumb {\n  background: #7f7f7f;\n  border-radius: 10px;\n}\n\n/* Handle on hover */\n.tableScroll ::-webkit-scrollbar-thumb:hover {\n  background: #7f7f7f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
