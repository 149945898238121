import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_ASSUMPTION,
  CLEAR_ASSUMPTION_STATE,
  FETCH_ASSUMPTION_FAILED,
  FETCH_ASSUMPTION_LIST_FAILED,
  FETCH_ASSUMPTION_LIST_PROGRESS,
  FETCH_ASSUMPTION_LIST_SUCCESS,
  FETCH_ASSUMPTION_PROGRESS,
  FETCH_ASSUMPTION_SUCCESS,
} from "./assumptionActions";
import { initialAssumption, initialAssumptionState } from "./defaultState";
import { AssumptionActions } from ".";

export const assumptionReducer = (
  state: IStoreState["assumption"] = initialAssumptionState,
  action: AssumptionActions,
) => {
  switch (action.type) {
    case FETCH_ASSUMPTION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ASSUMPTION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        // @ts-ignore
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ASSUMPTION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_ASSUMPTION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.assumption.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ASSUMPTION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.assumption.loading = LoadState.Loaded;
        draftState.assumption.data = data;
      });
      return newState;
    }
    case FETCH_ASSUMPTION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.assumption.loading = LoadState.Failed;
        draftState.assumption.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_ASSUMPTION: {
      const newState = produce(state, (draftState) => {
        draftState.assumption.loading = LoadState.NotLoaded;
        draftState.assumption.data = initialAssumption;
        draftState.assumption.error = null;
      });
      return newState;
    }

    case CLEAR_ASSUMPTION_STATE: {
      return initialAssumptionState;
    }

    default: {
      return state;
    }
  }
};
