import { ActionType } from "typesafe-actions";
import * as Actions from "../comments/comment.actions";

type Actions = typeof Actions;

export type CommentActions = ActionType<Actions>;

export * from "./defaultState";
export * from "./comment.actions";
export * from "./comments.types";
