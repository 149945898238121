import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

import { ICountryKey } from "./countrykey.types";

//=============================== COUNTRYPEDIA List ===============================

export const FETCH_COUNTRYKEY_LIST_PROGRESS = "FETCH_COUNTRYKEY_LIST_PROGRESS";
export const FETCH_COUNTRYKEY_LIST_SUCCESS = "FETCH_COUNTRYKEY_LIST_SUCCESS";
export const FETCH_COUNTRYKEY_LIST_FAILED = "FETCH_COUNTRYKEY_LIST_FAILED";

export const fetchCountrykeyListProgress = () =>
  action(FETCH_COUNTRYKEY_LIST_PROGRESS);
export const fetchCountrykeyListSuccess = (
  data: ICountryKey[],
  totalRecords: number,
) => action(FETCH_COUNTRYKEY_LIST_SUCCESS, { data, totalRecords });
export const fetchCountrykeyListFailed = () =>
  action(FETCH_COUNTRYKEY_LIST_FAILED);

export const fetchCountrykeyListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCountrykeyListProgress());
      const res = await api.get(`/country/get-country-key${searchQuery}`);
      const data: ICountryKey[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      console.log(data, "records");

      dispatch(fetchCountrykeyListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCountrykeyListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert COUNTRYPEDIA ===============================

export const upsertCountrykeyAsync =
  (
    data: ICountryKey,

    onCallback: (isSuccess: boolean, COUNTRYKEY?: ICountryKey) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    let { create_ts, insert_ts, rowId, ...rest } = data;

    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/country/upsert-country-key", {
        ...rest,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Country Key Generated successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message ||
            "Error occurred while saving COUNTRYPEDIA",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
//***********************fetching single data **********************//

export const FETCH_COUNTRYKEY_PROGRESS = "FETCH_COUNTRYKEY_PROGRESS";
export const FETCH_COUNTRYKEY_SUCCESS = "FETCH_COUNTRYKEY_SUCCESS";
export const FETCH_COUNTRYKEY_FAILED = "FETCH_COUNTRYKEY_FAILED";

export const fetchCountrykeyProgress = () => action(FETCH_COUNTRYKEY_PROGRESS);
export const fetchCountrykeySuccess = (data: ICountryKey) =>
  action(FETCH_COUNTRYKEY_SUCCESS, { data });
export const fetchCountrykeyFailed = (errorMessage: string) =>
  action(FETCH_COUNTRYKEY_FAILED, { errorMessage });

export const fetchCountrykeyAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCountrykeyProgress());
      const res = await api.get(
        `/country/get-country-key?country_key_uuid=${uuid}`,
      );
      const data: ICountryKey[] = res.data.data;
      console.log(data, "data");
      if (data.length > 0) {
        dispatch(fetchCountrykeySuccess(data[0]));
      } else {
        dispatch(fetchCountrykeyFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchCountrykeyFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_COUNTRYKEY = "CLEAR_COUNTRYKEY";
export const CLEAR_COUNTRYKEY_STATE = "CLEAR_COUNTRYKEY_STATE";
export const clearCountrykey = () => action(CLEAR_COUNTRYKEY);
export const clearCountrykeyState = () => action(CLEAR_COUNTRYKEY_STATE);
