import { ActionType } from "typesafe-actions";

import * as BomAction from "./assumptionActions";

type Actions = typeof BomAction;

export type AssumptionActions = ActionType<Actions>;

export * from "./assumptionActions";
export * from "./assumptionReducer";
export * from "./defaultState";
