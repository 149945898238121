export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum ERROR_MESSAGES {
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
  CUSTOMERS = "ENTITIES|CUSTOMERS|LATEST_CUSTOMER",
  CONTACTS = "ENTITIES|CONTACTS|LATEST_CONTACTS",
  USERS = "USERS|USERS|LATEST_USER",
  ROLES = "latest_roles",
  SECURITY = "SECURITY|SECURITY|ROLE_MODULE",
  DRAWING = "DRAWING|DRAWING|LATEST_DRAWING",
  ASSUMPTION = "ASSUMPTION|ASSUMPTION|LATEST_ASSUMPTION",
  SALARY = "SALARY|SALARY|LATEST_SALARY",
  COUNTRYPEDIA = "COUNTRYPEDIA|COUNTRYPEDIA|LATEST_COUNTRYPEDIA",
  COUNTRYKEY = "COUNTRYKEY|COUNTRYKEY|LATEST_COUNTRYKEY",
  // APPROVAL = "approval"
}
